<template>
  <li class="item equipment-item" v-on:click="pick(equipment.id)">
    <div
      class="equipment-icon"
      v-bind:class="{
        'btn-success': equipment.is_connected && !equipment.has_active_alarms,
        'btn-warning': !equipment.is_connected && !equipment.has_active_alarms,
        'btn-danger': equipment.has_active_alarms
      }"
    >
      <i
        class="fa"
        v-bind:class="{
          'fa fa-exclamation-triangle':
            equipment.has_active_alarms || !equipment.is_connected,
          'fa-check': !equipment.has_active_alarms
        }"
      >
      </i>
    </div>
    <div class="product-info">
      <span class="product-title">
        {{ equipment.nome.toUpperCase() }} - {{ equipment.modelo }}</span
      >
      <span class="product-description"
        >{{ equipment.modelo }} - {{ equipment.numero_serie }}</span
      >
    </div>
  </li>
</template>

<script>
export default {
  name: "EquipmentListItem",
  props: {
    equipment: Object
  },
  methods: {
    pick: function(value) {
      this.$emit("pick", value);
    }
  }
};
</script>

<style scoped>
.tab-search {
  padding-bottom: 200px !important;
}
.equipment-item {
  opacity: 0.8;
}
.equipment-item:hover {
  cursor: pointer;
}
.equipment-item .info-box {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.equipment-item .info-box:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 1);
  cursor: pointer;
}
.equipment-enabled {
  opacity: 1;
}
.equipment-icon {
  float: left;
  width: 50px;
  height: 50px;
  padding: 5px 10px;
  font-size: 2em;
  border-radius: 10px;
}
.equipment-icon:hover {
  opacity: 0.5;
}
</style>
